<template>
  <div>
    <b-card title="Translations">
      <validation-observer ref="simpleRules">
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col sm="12">
                <div>
                  <!-- Row Loop -->
                  <b-row
                    v-for="(item, index) in itemData.translations"
                    :id="item.id"
                    :key="item.id"
                    ref="row"
                  >
                    <!-- translation language -->
                    <b-col md="4">
                      <validation-provider
                        #default="{ errors }"
                        name="language"
                        rules="required"
                      >
                        <b-form-group
                          label="Language"
                          label-for="language"
                          :state="errors.length > 0 ? false : null"
                        >
                          <v-select
                            :id="'language' + index"
                            v-model="tranLocaleSelect[index]"
                            :state="
                              tranLocaleSelect[index] === null ? false : true
                            "
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="localeOptions"
                            :selectable="
                              (option) => !option.value.includes('select_value')
                            "
                            label="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- translation name -->
                    <b-col md="4">
                      <b-form-group label="Name" label-for="name">
                        <validation-provider
                          #default="{ errors }"
                          name="name"
                          rules="required"
                        >
                          <b-form-input
                            :id="'TranslationName' + index"
                            v-model="itemData.translations[index].name"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- translation short description -->
                    <b-col md="4">
                      <b-form-group
                        label="Short Description"
                        label-for="short-description"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="short-description"
                          rules="required"
                        >
                          <b-form-input
                            :id="'short-description' + index"
                            v-model="
                              itemData.translations[index].short_description
                            "
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- translation description -->
                    <b-col md="12">
                      <b-form-group label="Description" label-for="description">
                        <validation-provider
                          #default="{ errors }"
                          name="description"
                          rules="required"
                        >
                          <vue-editor
                            :id="'translationDescription' + index"
                            v-model="itemData.translations[index].description"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Remove Button -->
                    <b-col lg="2" md="3" class="mb-50">
                      <b-button
                        v-if="index > 0"
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="mt-0 mt-md-2"
                        @click="translationsremoveItem(index)"
                      >
                        <feather-icon icon="XIcon" class="mr-25" />
                        <span>Remove</span>
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <hr />
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <!-- add new button -->
              <b-col sm="2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  block
                  @click="translationsrepeateAgain"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>Add New</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
          <!-- submit button -->
          <b-col>
            <b-button
              size="lg"
              variant="primary"
              type="submit"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import { heightTransition } from '@core/mixins/ui/transition'
import BCardCode from '@core/components/b-card-code'
import { required } from '@validations'
export default {
  components: {
    BCardCode,
    VueEditor,
  },
  mixins: [heightTransition],
  data() {
    return {
      showDismissibleAlert: false,
      errors_back: [],
      id: 0,
      localeOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: 'en',
          text: 'English',
        },
        {
          value: 'ar',
          text: 'Arabic',
        },
      ],
      tranLocaleSelect: [null],
      localeOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: 'en',
          text: 'English',
        },
        {
          value: 'ar',
          text: 'Arabic',
        },
      ],
      itemData: {
        translations: [
          {
            locale: '',
            name: '',
            short_description: '',
            description: '',
          },
        ],
      },
      required,
    }
  },
  created() {
    this.id = this.$route.params.id
  },
  methods: {
    // submit add
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          for (let trn in this.itemData.translations) {
            this.itemData.translations[trn].locale =
              this.tranLocaleSelect[trn].value
          }
          axios
            .post(
              'items-guide/' + this.id + '/translations/store',
              this.itemData
            )
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.back(1)
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    translationsrepeateAgain() {
      // this.refheightForm = this.$refs.translationForm
      this.itemData.translations.push({
        //  id: this.nextTodoId += this.nextTodoId,
      })
      this.tranLocaleSelect.push({
        //  id: this.nextTodoId += this.nextTodoId,
      })
    },
    translationsremoveItem(index) {
      if (this.itemData.translations.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.itemData.translations.splice(index, 1)
        this.tranLocaleSelect.splice(index, 1)
      }
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
<style lang="scss">
 
</style>
